import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'
import { StaticImage } from 'gatsby-plugin-image'
import s1 from '../images/pragicts-software-development-process.png'

const WebsiteDeliveryScope = () => {
    return (
        <Layout pageTitle="PragICTS | Website Delivery Scope" og_desc="">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>WEBSITE DELIVERY <br/>
<span> SCOPE</span></h2>
                                <p></p>
                                <div className="horizonral-subtitle"><span>SCOPE</span></div>
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
                    <section data-scrollax-parent="true" id="sec1">
                        <div className="section-subtitle left-pos"  data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>SCOPE</div>
                        <div className="container main-about">
                          

<p style={{fontSize: '13px' }}>PragICTS provides the following scope for the design and implementation of websites.</p><br/>

                            <h2>Default</h2>
                            
                            <p >
                            <div className="time-line-icon">
                                    <i className="fal pragicts-bespoke-icon-new"></i>
                            </div>
                            < h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>
                            (1) Bespoke Website Design Approach and Implementation
                            </h3> 
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                            PragICTS specializes in creating custom website designs tailored to meet the specific needs of each client. This non-WordPress-based approach allows for greater flexibility and uniqueness in design, ensuring that the website stands out and aligns perfectly with the client’s brand identity.
                            </h6>
                        </p>
                        <p >
                            <div className="time-line-icon">
                                    <i className="fal pragicts-security-icon-new"></i>
                            </div>
                            < h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>
                            (2) Engineered for Performance and Security
                            </h3> 
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                            Each website is developed with a focus on high performance and robust security measures. This ensures fast loading times and a secure environment for users, protecting sensitive data and enhancing the overall user experience.
                            </h6>
                        </p>
                        <p >
                            <div className="time-line-icon">
                                    <i className="fal pragicts-audience-icon-new"></i>
                            </div>
                            < h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>
                            (3) Target Audience/Domain Segmentation
                            </h3> 
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                            PragICTS conducts thorough research to understand the target audience and market segmentation. This targeted approach allows for tailored content and features that resonate with specific user groups, improving engagement and conversion rates.
                            </h6>
                        </p>
                        <p >
                            <div className="time-line-icon">
                                    <i className="fal pragicts-responsive-icon-new"></i>
                            </div>
                            < h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>
                            (4) Fully Responsive Design
                            </h3> 
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                            The websites are designed to be fully responsive, ensuring a seamless user experience across all IT-enabled devices, including mobile phones, tablets, laptops, and desktops. This adaptability is crucial in today’s multi-device environment, where users expect consistent performance regardless of their chosen device.
                            </h6>
                        </p>
                        <p >
                            <div className="time-line-icon">
                                    <i className="fal pragicts-optimization-icon-new"></i>
                            </div>
                            < h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>
                            (5) On-Page SEO Optimization
                            </h3> 
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                            On-page SEO is an integral part of the website’s design and content strategy. PragICTS will optimize key elements such as titles, keywords, descriptions, and image tagging to improve search engine visibility and attract organic traffic.
                            </h6>
                        </p>
                        <p >
                            <div className="time-line-icon">
                                    <i className="fal pragicts-graph-icon-new"></i>
                            </div>
                            < h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>
                            (6) Open Graph Implementation
                            </h3> 
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                            To enhance content sharing on social media platforms, PragICTS implements Open Graph tags. This ensures that shared links display rich information, such as images and descriptions, making content more appealing and increasing click-through rates.<br/>Update the following url with the provided content
                            </h6>
                        </p>
                        <p >
                            <div className="time-line-icon">
                                    <i className="fal pragicts-analytics-icon-new"></i>
                            </div>
                            < h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>
                            (7) Google Analytics Integration
                            </h3> 
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                            The integration of Google Analytics provides valuable insights into website traffic and user behavior. This data enables clients to make informed decisions based on visitor trends, enhancing marketing strategies and user engagement.
                            </h6>
                        </p>
                        <p >
                            <div className="time-line-icon">
                                    <i className="fal pragicts-site-icon-new"></i>
                            </div>
                            < h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>
                            (8) Site Map XML Submission
                            </h3> 
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                            PragICTS will create and submit an XML sitemap to search engines, facilitating better indexing of the website. This helps search engines understand the website structure and improves visibility in search results.
                            </h6>
                        </p>
                        <p >
                            <div className="time-line-icon">
                                    <i className="fal pragicts-cdn-icon-new"></i>
                            </div>
                            < h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>
                            (9) CDN (Content Delivery Network) Integration
                            </h3> 
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                            By integrating a CDN, PragICTS ensures fast content delivery across the globe. This reduces latency and enhances loading times, providing users with a superior browsing experience regardless of their geographical location.
                            </h6>
                        </p>
                        <p >
                            <div className="time-line-icon">
                                    <i className="fal pragicts-ssl-icon-new"></i>
                            </div>
                            < h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>
                            (10) SSL Implementation
                            </h3> 
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                            Security is a top priority, and PragICTS will implement SSL certificates to encrypt data transferred between the website and its users. This not only protects sensitive information but also builds trust with visitors, as websites with SSL are marked as secure by browsers.
                            </h6>
                        </p>
                        <p >
                            <div className="time-line-icon">
                                    <i className="fal pragicts-integration-icon-new"></i>
                            </div>
                            < h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>
                            (11) Social Platform Integration
                            </h3> 
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                            To maximize online presence and engagement, PragICTS will integrate various social media platforms into the website. This includes easy sharing options, social media feeds, and login features, allowing users to connect seamlessly with the brand across multiple channels.
                            </h6>
                        </p>




<div>










</div> 


<h2 style={{marginTop:'20px'}}>Optional</h2>

<p >                        
                            <div className="time-line-icon">
                                    <i className="fal pragicts-authoring-icon-new"></i>
                            </div>
                            < h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>
                            (1) Content Authoring
                            </h3> 
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                            PragICTS offers professional content authoring services to create engaging and relevant text for websites. This includes writing clear, persuasive copy that resonates with the target audience, ensuring that the messaging aligns with the brand’s voice and objectives.
                            </h6>
                        </p>
                        <p >
                            <div className="time-line-icon">
                                    <i className="fal pragicts-branding-icon-new"></i>
                            </div>
                            < h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>
                            (2) Branding
                            </h3> 
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                            The team will work closely with clients to develop a strong brand identity, including logo design, color schemes, typography, and overall visual style. This cohesive branding strategy helps establish a memorable presence and fosters recognition in the marketplace.
                            </h6>
                        </p>
                        <p >
                            <div className="time-line-icon">
                                    <i className="fal pragicts-setup-icon-new"></i>
                            </div>
                            < h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>
                            (3) Social Platform Implementation and Setup
                            </h3> 
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                            PragICTS will assist in setting up and integrating social media platforms, ensuring that they align with the website and branding strategy. This includes creating profiles, configuring settings, and linking social media to the website for seamless user engagement.
                            </h6>
                        </p>
                        <p >
                            <div className="time-line-icon">
                                    <i className="fal pragicts-hosting-icon-new"></i>
                            </div>
                            < h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>
                            (4) Tier 1 Hosting on AWS or Microsoft Azure
                            </h3> 
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                            To guarantee optimal performance and reliability, PragICTS offers Tier 1 hosting solutions on leading platforms like AWS and Microsoft Azure. These hosting services ensure high availability, robust security, and scalability to accommodate growing traffic demands.

                            </h6>
                        </p>
                        <p >
                            <div className="time-line-icon">
                                    <i className="fal pragicts-domain-icon-new"></i>
                            </div>
                            < h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>
                            (5) Domain Name Procurement and Configuration
                            </h3> 
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                            PragICTS will facilitate the procurement and configuration of domain names that reflect the brand and are easy to remember. This includes managing DNS settings to ensure the domain is correctly linked to the website.

                            </h6>
                        </p>
                        <p >
                            <div className="time-line-icon">
                                    <i className="fal pragicts-purchasing-icon-new"></i>
                            </div>
                            < h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>
                            (6) Image Procurement
                            </h3> 
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                            For a visually appealing website, PragICTS can source high-quality images that enhance the overall aesthetic. This includes procuring licensed images that align with the brand and its messaging, ensuring all visual content is professional and relevant.

                            </h6>
                        </p>
                        <p >
                            <div className="time-line-icon">
                                    <i className="fal pragicts-photo-icon-new"></i>
                            </div>
                            < h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>
                            (7) Photo Shoot
                            </h3> 
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                            In addition to stock images, PragICTS offers custom photo shoot services to capture unique visuals that represent the brand authentically. This includes professional photography tailored to the client’s specific needs, showcasing products, services, or events.

                            </h6>
                        </p>
                        <p >
                            <div className="time-line-icon">
                                    <i className="fal pragicts-drone-icon-new"></i>
                            </div>
                            < h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>
                            (8) Drone Footage
                            </h3> 
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                            To add a unique perspective, PragICTS provides drone videography services. This can be particularly beneficial for showcasing large properties, events, or landscapes, offering engaging content that enhances the visual storytelling of the brand.

                            </h6>
                        </p>
                        <p >
                            <div className="time-line-icon">
                                    <i className="fal pragicts-production-icon-new"></i>
                            </div>
                            < h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>
                            (9) Video Compilation
                            </h3> 
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                            The team can compile and edit video content to create compelling promotional materials. This includes combining footage, adding graphics, and incorporating music to produce polished videos that effectively communicate the brand’s message.

                            </h6>
                        </p>
                        <p >
                            <div className="time-line-icon">
                                    <i className="fal pragicts-registration-icon-new"></i>
                            </div>
                            < h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>
                            (10) Google Business Registration
                            </h3> 
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                            PragICTS will assist with registering the business on Google Business, ensuring that the company appears in local search results. This enhances visibility and provides essential information to potential customers, such as location, hours, and contact details.

                            </h6>
                        </p>
                        <p >
                            <div className="time-line-icon">
                                    <i className="fal pragicts-management-icon-new"></i>
                            </div>
                            < h3 style={{fontSize: '15px',fontWeight:'bold',color:'black'}}>
                            (11) Content Management Module
                            </h3> 
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                            To empower clients with control over their website content, PragICTS will implement a content management module. This user-friendly system allows clients to easily update text, images, and other content without needing technical expertise, ensuring the website remains current and relevant.

                            </h6>
                        </p>
                        <p >
                           
                       
                            <h6 style={{fontSize: '13px',textAlign:'justify'}}>
                            Overall, these comprehensive services ensure that the websites developed by PragICTS are not only visually appealing but also optimized for performance, security, and user engagement, positioning clients for success in the digital landscape.

                            </h6>
                        </p>























                        </div>
                     
                        <div className="bg-parallax-module" data-position-top="50"  data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
                        <div className="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
                        <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
                        <div className="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
                        <div className="sec-lines"></div>
                    </section>
                        
                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default WebsiteDeliveryScope
